import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { AccountCreationUiModuleBody } from '../../../../../API';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { useLocales, useTheme } from '../../../../../hooks';
import { Controller, useFormContext } from 'react-hook-form';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { FormControlLabel, Switch } from '@mui/material';
import { LokaliseField } from '../../../../shared/LokaliseFields';

export const testIds = {
  formBody: 'account-creation.form-body',
  formField: (name: keyof AccountCreationUiModuleBody): string => `account-creation.form-field.${name}`
};

type AccountCreationFieldProps = {
  name: keyof AccountCreationUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
};

const AccountCreationForm = forwardRef<UIModuleTypeForm<AccountCreationUiModuleBody>>(() => {
  const { control, watch } = useFormContext<AccountCreationUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: AccountCreationFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.account_creation.fields.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.account_creation.headers.images')}</SectionHeader>
        <AssetPicker name="vixLogo" assetType={AssetTypes.accountCreation.vixLogo} required />
        <AssetPicker name="partnerLogo" assetType={AssetTypes.accountCreation.partnerLogo} />
      </div>
      <div>
        <div>
          <SectionHeader>{t('layouts.account_creation.headers.account_creation_keys')}</SectionHeader>
          <LokaliseField
            name="header"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('header')}
            required
          />
          <LokaliseField
            name="subHeader"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('subHeader')}
          />
          <LokaliseField
            name="emailText"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('emailText')}
            required
          />
          <LokaliseField
            name="passwordText"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('passwordText')}
            required
          />
          <LokaliseField
            name="vixDisclaimer"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('vixDisclaimer')}
            required
          />
          <LokaliseField
            name="partnerDisclaimer"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('partnerDisclaimer')}
          />
        </div>
        <div>
          <SectionHeader>{t('layouts.account_creation.headers.sign_in_keys')}</SectionHeader>
          <LokaliseField
            name="signInTitle"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('signInTitle')}
          />
          <LokaliseField
            name="subHeaderSignIn"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('subHeaderSignIn')}
          />
          <LokaliseField
            name="signInCta"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('signInCta')}
            required
          />
          <LokaliseField
            name="signUpCta"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('signUpCta')}
            required
          />
          <LokaliseField
            name="mainCtaSignIn"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('mainCtaSignIn')}
          />
          <LokaliseField
            name="secondaryCtaSignIn"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('secondaryCtaSignIn')}
          />
          <LokaliseField
            name="createAccountCtaSignIn"
            control={control}
            toTranslate="layouts.account_creation.fields"
            dataTestId={testIds.formField('createAccountCtaSignIn')}
          />
        </div>
        <Controller
          control={control}
          name="legalCheckbox"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={<Switch color={formControlColor} checked={value} onChange={onChange} />}
              label={t('layouts.account_creation.fields.legalCheckbox')}
            />
          )}
        />
      </div>
    </FormBody>
  );
});

AccountCreationForm.displayName = 'AccountCreationForm';

export default AccountCreationForm;
