import React, { forwardRef } from 'react';
import { AccountLoadingUiModuleBody } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { useFormContext } from 'react-hook-form';
import { FormBody } from '../../styles';
import { LokaliseField } from '../../../../shared/LokaliseFields';

export const testIds = {
  formBody: 'account-loading.form-body',
  formField: (name: keyof AccountLoadingUiModuleBody): string => `account-loading.form-field.${name}`
};

const AccountLoadingForm = forwardRef<UIModuleTypeForm<AccountLoadingUiModuleBody>>(() => {
  const { control } = useFormContext<AccountLoadingUiModuleBody>();

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <LokaliseField
          name="accountLoading"
          control={control}
          toTranslate="layouts.account_loading.fields"
          dataTestId={testIds.formField('accountLoading')}
          required
        />
      </div>
    </FormBody>
  );
});

AccountLoadingForm.displayName = 'AccountLoadingForm';

export default AccountLoadingForm;
