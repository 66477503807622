import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { PlaybackPaywallUiModuleBody } from '../../../../../API';
import { useFormContext } from 'react-hook-form';
import { FormBody, SectionHeader } from '../../styles';
import { useLocales } from '../../../../../hooks';
import { LokaliseField } from '../../../../shared/LokaliseFields';

export const testIds = {
  root: 'playback-paywall.root',
  formBody: 'playback-paywall.form-body',
  formField: (name: keyof PlaybackPaywallUiModuleBody): string => `playback-paywall.form-field.${name}`
};

const PlaybackPaywallForm = forwardRef<UIModuleTypeForm<PlaybackPaywallUiModuleBody>>(() => {
  const { control } = useFormContext<PlaybackPaywallUiModuleBody>();
  const { t } = useLocales();

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('layouts.playback_paywall.headers.trailer')}</SectionHeader>
        <LokaliseField
          name="postTrailerPrimaryText"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={testIds.formField('postTrailerPrimaryText')}
          required
        />
        <LokaliseField
          name="postTrailerPrimaryCta"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={testIds.formField('postTrailerPrimaryCta')}
          required
        />
        <LokaliseField
          name="postTrailerSecondaryCtaAuth"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={testIds.formField('postTrailerSecondaryCtaAuth')}
        />
        <LokaliseField
          name="postTrailerSecondaryCtaAnon"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={testIds.formField('postTrailerSecondaryCtaAnon')}
        />
      </div>
      <div>
        <SectionHeader>{t('layouts.playback_paywall.headers.up_next')}</SectionHeader>
        <LokaliseField
          name="postEpisodePrimaryCta"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={testIds.formField('postEpisodePrimaryCta')}
          required
        />
        <LokaliseField
          name="postEpisodeSecondaryCtaAuth"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={testIds.formField('postEpisodeSecondaryCtaAuth')}
        />
        <LokaliseField
          name="postEpisodeSecondaryCtaAnon"
          control={control}
          toTranslate="layouts.playback_paywall.fields"
          dataTestId={testIds.formField('postEpisodeSecondaryCtaAnon')}
        />
      </div>
    </FormBody>
  );
});

PlaybackPaywallForm.displayName = 'PlaybackPaywallForm';

export default PlaybackPaywallForm;
