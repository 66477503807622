import React, { forwardRef } from 'react';
import { UIModuleTypeForm } from '../../UIModuleForm';
import {
  NewPartnerLandingUiModuleBody,
  NewPartnerLandingUiModuleFaqQuestionsBody,
  NewPartnerLandingUiModuleStepsToRedeemBody
} from '../../../../../API';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { useLocales, useTheme } from '../../../../../hooks';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { Add, Delete } from '@mui/icons-material';
import { Sortable } from '../../../../shared/Sortable';
import generateId from '../../../../../utils/generateId';
import { LokaliseField } from '../../../../shared/LokaliseFields';

export const testIds = {
  formBody: 'new-partner-landing.form-body',
  formField: (
    name:
      | keyof NewPartnerLandingUiModuleBody
      | keyof NewPartnerLandingUiModuleFaqQuestionsBody
      | keyof NewPartnerLandingUiModuleStepsToRedeemBody
  ): string => `new-partner-landing.form-field.${name}`,
  faqQuestions: 'new-partner-landing.faqQuestions',
  faqQuestionsAddButton: 'new-partner-landing.faqQuestions-add-button',
  faqQuestionsRemoveButton: 'new-partner-landing.faqQuestions-remove-button',
  stepsToRedeem: 'new-partner-landing.stepsToRedeem',
  stepsToRedeemAddButton: 'new-partner-landing.stepsToRedeem-add-button',
  stepsToRedeemRemoveButton: 'new-partner-landing.stepsToRedeem-remove-button'
};

const NewPartnerLandingForm = forwardRef<UIModuleTypeForm<NewPartnerLandingUiModuleBody>>(() => {
  const { control, watch } = useFormContext<NewPartnerLandingUiModuleBody>();
  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const {
    append: appendFaqQuestions,
    remove: removeFaqQuestions,
    fields: fieldsFaqQuestions,
    replace: replaceFaqQuestionsMethod
  } = useFieldArray({ control, name: 'faqQuestions' });

  const {
    append: appendStepsToRedeem,
    remove: removeStepsToRedeem,
    fields: fieldsStepsToRedeem,
    replace: replaceStepsToRedeemMethod
  } = useFieldArray({ control, name: 'stepsToRedeem' });

  const moduleId = watch('moduleId');

  const LokaliseFieldFaqQuestions = (
    name: keyof NewPartnerLandingUiModuleFaqQuestionsBody,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`faqQuestions.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.new_partner_landing.fields.faqQuestions.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseFieldStepsToRedeem = (
    name: keyof NewPartnerLandingUiModuleStepsToRedeemBody,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`stepsToRedeem.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.new_partner_landing.fields.stepsToRedeem.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: {
    name: keyof NewPartnerLandingUiModuleBody;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField>
            <AssetBrowser
              label={isRequired(t(`layouts.new_partner_landing.fields.${name}` as LocaleKeys))}
              assetId={moduleId || ''}
              assetType={assetType}
              value={value as string}
              data-testid={testIds.formField(name)}
              hasError={!!error}
              onChange={onChange}
            />
          </FormField>
        )}
      />
    );
  };

  const onAddFaqQuestions = () => {
    appendFaqQuestions({
      id: generateId(),
      question: '',
      answer: ''
    });
  };

  const onAddStepsToRedeem = () => {
    appendStepsToRedeem({
      id: generateId(),
      title: '',
      body: ''
    });
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Box>
        <SectionHeader>{t('layouts.new_partner_landing.headers.header')}</SectionHeader>
        <AssetPicker name="vixLogo" assetType={AssetTypes.newPartnerLanding.vixLogo} required />
        <AssetPicker name="partnerLogo" assetType={AssetTypes.newPartnerLanding.partnerLogo} />
        <LokaliseField
          name="header"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('header')}
          required
        />
        <LokaliseField
          name="subHeader"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('subHeader')}
        />
        <LokaliseField
          name="description"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('description')}
        />
        <div />
        <div>
          <Sortable
            list={fieldsStepsToRedeem}
            setList={replaceStepsToRedeemMethod}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsStepsToRedeem.map((faq, i) => (
              <Stack
                key={faq.id}
                data-testid={testIds.stepsToRedeem}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldStepsToRedeem('title', i)}
                {LokaliseFieldStepsToRedeem('body', i)}
                <IconButton
                  title={t('layouts.new_partner_landing.actions.remove_steps_to_redeem')}
                  onClick={() => removeStepsToRedeem(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.stepsToRedeemRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddStepsToRedeem}
            data-testid={testIds.stepsToRedeemAddButton}
          >
            {t('layouts.new_partner_landing.actions.add_steps_to_redeem')}
          </Button>
        </div>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.new_partner_landing.headers.plan_picker')}</SectionHeader>
        <LokaliseField
          name="planName"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('planName')}
          required
        />
        <LokaliseField
          name="planTier"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('planTier')}
        />
        <LokaliseField
          name="planDescription"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('planDescription')}
        />
        <LokaliseField
          name="planCost"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('planCost')}
        />
        <LokaliseField
          name="viewBenefits"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('viewBenefits')}
        />
        <LokaliseField
          name="benefitsList"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('benefitsList')}
        />
        <LokaliseField
          name="hideBenefits"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('hideBenefits')}
        />
        <LokaliseField
          name="legalAdsNotification"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('legalAdsNotification')}
        />
        <LokaliseField
          name="selectPlanCta"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('selectPlanCta')}
          required
        />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.new_partner_landing.headers.footer')}</SectionHeader>
        <LokaliseField
          name="legalDisclaimer"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('legalDisclaimer')}
          required
        />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.new_partner_landing.headers.faq')}</SectionHeader>
        <LokaliseField
          name="faqTitle"
          control={control}
          toTranslate="layouts.new_partner_landing.fields"
          dataTestId={testIds.formField('faqTitle')}
        />
        <div />
        <div>
          <Sortable
            list={fieldsFaqQuestions}
            setList={replaceFaqQuestionsMethod}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsFaqQuestions.map((faq, i) => (
              <Stack
                key={faq.id}
                data-testid={testIds.faqQuestions}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldFaqQuestions('question', i)}
                {LokaliseFieldFaqQuestions('answer', i)}
                <IconButton
                  title={t('layouts.new_partner_landing.actions.remove_faq_questions')}
                  onClick={() => removeFaqQuestions(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.faqQuestionsRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddFaqQuestions}
            data-testid={testIds.faqQuestionsAddButton}
          >
            {t('layouts.new_partner_landing.actions.add_faq_questions')}
          </Button>
        </div>
      </Box>
    </FormBody>
  );
});

NewPartnerLandingForm.displayName = 'NewPartnerLandingForm';

export default NewPartnerLandingForm;
