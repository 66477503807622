import React, { forwardRef } from 'react';
import { GeoBlockUiModuleBody } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { useFormContext } from 'react-hook-form';
import { FormBody } from '../../styles';
import { LokaliseField } from '../../../../shared/LokaliseFields';

export const testIds = {
  formBody: 'geo-block.form-body',
  formField: (name: keyof GeoBlockUiModuleBody): string => `geo-block.form-field.${name}`
};

const GeoBlockForm = forwardRef<UIModuleTypeForm<GeoBlockUiModuleBody>>(() => {
  const { control } = useFormContext<GeoBlockUiModuleBody>();

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <LokaliseField
          name="body"
          control={control}
          toTranslate="layouts.geo_block.fields"
          dataTestId={testIds.formField('body')}
          required
        />
      </div>
      <div>
        <LokaliseField
          name="legal"
          control={control}
          toTranslate="layouts.geo_block.fields"
          dataTestId={testIds.formField('legal')}
          required
        />
      </div>
    </FormBody>
  );
});

GeoBlockForm.displayName = 'GeoBlockForm';

export default GeoBlockForm;
