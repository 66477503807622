import React from 'react';
import { Control, Controller, Path } from 'react-hook-form';
import {
  AccountCreationUiModuleBody,
  AccountLoadingUiModuleBody,
  AiSearchUiModuleBody,
  GeoBlockUiModuleBody,
  LandingPromoUiModuleBody,
  LinkingAccountUiModuleBody,
  NewPartnerLandingUiModuleBody,
  OneBrandPromoUiModuleBody,
  OutboundUiModuleBody,
  PlanSummaryUiModuleBody,
  PlaybackPaywallUiModuleBody,
  ProfileUiModuleBody,
  RegistrationWallUiModuleBody,
  SpinPremiaUiModuleBody,
  TdsGiftCardsUiModuleBody,
  WhoIsWatchingUiModuleBody
} from '../../../API';
import { useLocales } from '../../../hooks';
import { LocaleKeys } from '../../../locales/i18n';
import { FormField } from '../../Layouts/UIModuleForm/styles';
import { LokaliseAutocomplete } from '../LokaliseAutocomplete';

type ControlType =
  | Control<AccountCreationUiModuleBody, unknown>
  | Control<AccountLoadingUiModuleBody, unknown>
  | Control<AiSearchUiModuleBody, unknown>
  | Control<GeoBlockUiModuleBody, unknown>
  | Control<LandingPromoUiModuleBody, unknown>
  | Control<LinkingAccountUiModuleBody, unknown>
  | Control<NewPartnerLandingUiModuleBody, unknown>
  | Control<OneBrandPromoUiModuleBody, unknown>
  | Control<OutboundUiModuleBody, unknown>
  | Control<PlanSummaryUiModuleBody, unknown>
  | Control<PlaybackPaywallUiModuleBody, unknown>
  | Control<ProfileUiModuleBody, unknown>
  | Control<RegistrationWallUiModuleBody, unknown>
  | Control<SpinPremiaUiModuleBody, unknown>
  | Control<TdsGiftCardsUiModuleBody, unknown>
  | Control<WhoIsWatchingUiModuleBody, unknown>;

type NameType =
  | keyof AccountCreationUiModuleBody
  | keyof AccountLoadingUiModuleBody
  | keyof AiSearchUiModuleBody
  | keyof GeoBlockUiModuleBody
  | keyof LandingPromoUiModuleBody
  | keyof LinkingAccountUiModuleBody
  | keyof NewPartnerLandingUiModuleBody
  | Path<OneBrandPromoUiModuleBody>
  | keyof OutboundUiModuleBody
  | keyof PlanSummaryUiModuleBody
  | keyof PlaybackPaywallUiModuleBody
  | Path<ProfileUiModuleBody>
  | keyof RegistrationWallUiModuleBody
  | Path<SpinPremiaUiModuleBody>
  | keyof TdsGiftCardsUiModuleBody
  | keyof WhoIsWatchingUiModuleBody;

type LokaliseFieldProps = {
  name: NameType;
  control: ControlType;
  toTranslate?: string;
  label?: string;
  dataTestId: string;
  required?: boolean;
};

export const LokaliseField: React.FC<LokaliseFieldProps> = ({
  name,
  control,
  toTranslate,
  label,
  dataTestId,
  required = false
}) => {
  const { t } = useLocales();

  return (
    <FormField>
      <Controller
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        control={control as Control<any>}
        name={name as NameType}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={!label ? t(`${toTranslate}.${name}` as LocaleKeys) : t(label as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={dataTestId}
          />
        )}
      />
    </FormField>
  );
};
