import React, { forwardRef } from 'react';
import { AiSearchUiModuleBody, AiSearchUiModuleBodySuggestionsBody } from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useLocales, useTheme } from '../../../../../hooks';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { Box, IconButton, Stack } from '@mui/material';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { Sortable } from '../../../../shared/Sortable';
import { Add, Delete } from '@mui/icons-material';
import Button from '../../../../shared/Button';
import generateId from '../../../../../utils/generateId';
import { makeStyles } from 'tss-react/mui';
import { LokaliseField } from '../../../../shared/LokaliseFields';

const MAX_SUGGESTIONS = 10;

const useStyles = makeStyles()(() => ({
  disabledButton: {
    filter: 'grayscale(1)',
    opacity: 0.5
  }
}));

export const testIds = {
  formBody: 'ai-search.form-body',
  formField: (name: keyof AiSearchUiModuleBody | keyof AiSearchUiModuleBodySuggestionsBody): string =>
    `ai-search.form-field.${name}`,
  suggestions: 'ai-search.suggestions',
  suggestionsAddButton: 'ai-search.suggestions-add-button',
  suggestionsRemoveButton: 'ai-search.suggestions-remove-button'
};

const AiSearchForm = forwardRef<UIModuleTypeForm<AiSearchUiModuleBody>>(() => {
  const { classes } = useStyles();
  const { control, watch } = useFormContext<AiSearchUiModuleBody>();
  const { append, remove, fields, replace } = useFieldArray({ control, name: 'suggestions' });

  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const moduleId = watch('moduleId');

  type AiSearchProps = keyof AiSearchUiModuleBody;

  const LokaliseFieldSuggestions = (
    name: keyof AiSearchUiModuleBodySuggestionsBody,
    position: number
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`suggestions.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.ai_search.fields.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={position === 0}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const AssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: {
    name: AiSearchProps;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField>
            <AssetBrowser
              label={isRequired(label)}
              assetId={moduleId || ''}
              assetType={assetType}
              value={value as string}
              data-testid={testIds.formField(name)}
              hasError={!!error}
              onChange={onChange}
            />
          </FormField>
        )}
      />
    );
  };

  const onAddSuggestions = () => {
    append({
      id: generateId(),
      suggestionText: ''
    });
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Box>
        <SectionHeader>{t('layouts.ai_search.header')}</SectionHeader>
        <LokaliseField
          name="searchTitle"
          control={control}
          toTranslate="layouts.ai_search.fields"
          dataTestId={testIds.formField('searchTitle')}
          required
        />
        <LokaliseField
          name="searchSubTitle"
          control={control}
          toTranslate="layouts.ai_search.fields"
          dataTestId={testIds.formField('searchSubTitle')}
          required
        />
        <LokaliseField
          name="searchPlaceholder"
          control={control}
          toTranslate="layouts.ai_search.fields"
          dataTestId={testIds.formField('searchPlaceholder')}
          required
        />
        <AssetPicker
          name="backgroundFillImage"
          label={t('layouts.ai_search.fields.backgroundFillImage')}
          assetType={AssetTypes.aiSearch.backgroundFillImage}
        />
        <div>
          <Sortable list={fields} setList={replace} ghostClass="sortableGhost" dragClass="sortableDragDefault">
            {fields.map((suggestion, i) => (
              <Stack
                key={suggestion.id}
                data-testid={testIds.suggestions}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldSuggestions('suggestionText', i)}
                <IconButton
                  title={t('layouts.ai_search.actions.remove_suggestions')}
                  disabled={fields.length === 1}
                  onClick={() => remove(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.suggestionsRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            classes={{ disabled: classes.disabledButton }}
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddSuggestions}
            disabled={fields.length >= MAX_SUGGESTIONS}
            data-testid={testIds.suggestionsAddButton}
          >
            {t('layouts.ai_search.actions.add_suggestion')}
          </Button>
        </div>
      </Box>
    </FormBody>
  );
});

AiSearchForm.displayName = 'AiSearchForm';

export default AiSearchForm;
