import React, { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RegistrationWallUiModuleBody } from '../../../../../API';
import { useLocales } from '../../../../../hooks';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { LokaliseField } from '../../../../shared/LokaliseFields';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { UIModuleTypeForm } from '../../UIModuleForm';

export const testIds = {
  formBody: 'reg_wall.form-body',
  formField: (name: keyof RegistrationWallUiModuleBody): string => `reg_wall.form-field.${name}`
};

type RegWallFieldProps = {
  name: keyof RegistrationWallUiModuleBody;
  required?: boolean;
  'data-testid'?: string;
};

const RegWallForm = forwardRef<UIModuleTypeForm<RegistrationWallUiModuleBody>>(() => {
  const { control, watch } = useFormContext<RegistrationWallUiModuleBody>();
  const { t } = useLocales();

  const moduleId = watch('moduleId');

  const AssetPicker = ({
    name,
    assetType,
    required = false
  }: RegWallFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            label={markAsRequired(t(`general.${name.replace('Image', '_image')}`), required)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  return (
    <FormBody data-testid={testIds.formBody}>
      <div>
        <SectionHeader>{t('general.images')}</SectionHeader>
        <AssetPicker name="landscapeImage" assetType={AssetTypes.regWall.landscape} />
        <AssetPicker name="portraitImage" assetType={AssetTypes.regWall.portrait} />
        <AssetPicker name="desktopImage" assetType={AssetTypes.regWall.desktop} />
        <AssetPicker name="mobileImage" assetType={AssetTypes.regWall.mobile} />
        <AssetPicker name="ctvImage" assetType={AssetTypes.regWall.ctv} />
        <AssetPicker name="tabletImage" assetType={AssetTypes.regWall.tablet} />
      </div>
      <div>
        <SectionHeader>{t('layouts.reg_wall.headers.reg_wall_keys')}</SectionHeader>
        <LokaliseField
          name="header"
          control={control}
          toTranslate="layouts.reg_wall.fields"
          dataTestId={testIds.formField('header')}
          required
        />
        <LokaliseField
          name="valuePropositionsList"
          control={control}
          toTranslate="layouts.reg_wall.fields"
          dataTestId={testIds.formField('valuePropositionsList')}
        />
        <LokaliseField
          name="registerCta"
          control={control}
          toTranslate="layouts.reg_wall.fields"
          dataTestId={testIds.formField('registerCta')}
        />
        <LokaliseField
          name="signInCta"
          control={control}
          toTranslate="layouts.reg_wall.fields"
          dataTestId={testIds.formField('signInCta')}
        />
        <LokaliseField
          name="providerCta"
          control={control}
          toTranslate="layouts.reg_wall.fields"
          dataTestId={testIds.formField('providerCta')}
        />
      </div>
    </FormBody>
  );
});

RegWallForm.displayName = 'RegWallForm';

export default RegWallForm;
