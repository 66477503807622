import React, { forwardRef } from 'react';
import {
  TdsGiftCardsUiModuleBody,
  TdsGiftCardsUiModuleCodeHintBody,
  SharedModalBody,
  TdsGiftCardsUiModuleTopUpDoneBody,
  BackgroundBody
} from '../../../../../API';
import { UIModuleTypeForm } from '../../UIModuleForm';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useLocales, useTheme } from '../../../../../hooks';
import { FormBody, FormField, SectionHeader } from '../../styles';
import { Box, Button, FormControlLabel, IconButton, Stack, Switch } from '@mui/material';
import { LokaliseAutocomplete } from '../../../../shared/LokaliseAutocomplete';
import { LocaleKeys } from '../../../../../locales/i18n';
import { AssetTypes, AssetTypesEnum } from '../../../../../utils/assetTypes';
import { markAsRequired } from '../../../../../utils/formHelpers';
import AssetBrowser from '../../../../Assets/AssetBrowser';
import { Sortable } from '../../../../shared/Sortable';
import { Add, Delete } from '@mui/icons-material';
import generateId from '../../../../../utils/generateId';
import { LokaliseField } from '../../../../shared/LokaliseFields';

type TdsGiftCardsProps = keyof TdsGiftCardsUiModuleBody;
type TdsGiftCardsCheckboxProps = keyof Pick<
  TdsGiftCardsUiModuleBody,
  'stepOneCheckbox' | 'stepThreeACheckbox' | 'stepThreeBCheckboxTopUp' | 'stepThreeCCheckboxDtc' | 'stepFourCheckbox'
>;
type TdsGiftCardsErrorsProps = Pick<
  TdsGiftCardsUiModuleBody,
  'notSupportedPaymentMethodError' | 'unableToRedeemError' | 'usedAccountError'
>;

interface BackgroundFieldProps {
  prop: 'stepOneBackground' | 'stepFourBackground';
  name: keyof BackgroundBody;
  required?: boolean;
  'data-testid'?: string;
}

export const testIds = {
  formBody: 'tds-gift-cards.form-body',
  formField: (
    name:
      | keyof TdsGiftCardsUiModuleBody
      | keyof TdsGiftCardsUiModuleCodeHintBody
      | keyof SharedModalBody
      | keyof TdsGiftCardsUiModuleTopUpDoneBody
      | keyof BackgroundBody
  ): string => `tds-gift-cards.form-field.${name}`,
  codeHint: 'tds-gift-cards.codeHint',
  codeHintAddButton: 'tds-gift-cards.codeHint-add-button',
  codeHintRemoveButton: 'tds-gift-cards.codeHint-remove-button',
  error: 'tds-gift-cards.error',
  errorAddButton: 'tds-gift-cards.error-add-button',
  errorRemoveButton: 'tds-gift-cards.error-remove-button'
};

const TdsGiftCardsForm = forwardRef<UIModuleTypeForm<TdsGiftCardsUiModuleBody>>(() => {
  const { control, watch } = useFormContext<TdsGiftCardsUiModuleBody>();

  const { t } = useLocales();
  const { formControlColor } = useTheme();

  const {
    append: appendCodeHints,
    remove: removeCodeHints,
    fields: fieldsCodeHints,
    replace: replaceCodeHintsMethod
  } = useFieldArray({ control, name: 'stepTwoCodeHints' });

  const moduleId = watch('moduleId');

  const LokaliseFieldCodeHints = (
    name: keyof TdsGiftCardsUiModuleCodeHintBody,
    position: number,
    required = false
  ): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={`stepTwoCodeHints.${position}.${name}`}
        render={({ field: { value, onChange } }) => (
          <LokaliseAutocomplete
            label={t(`layouts.tds_gift_cards.fields.stepTwoCodeHints.${name}` as LocaleKeys)}
            name={name}
            value={value as string}
            onChange={onChange}
            required={required}
            data-testid={testIds.formField(name)}
          />
        )}
      />
    </FormField>
  );

  const LokaliseFieldTopUpDone = (
    parent: keyof Pick<TdsGiftCardsUiModuleBody, 'topUpDone'>,
    name: keyof TdsGiftCardsUiModuleTopUpDoneBody,
    required = false
  ): React.ReactElement => {
    return (
      <FormField>
        <Controller
          control={control}
          name={`${parent}.${name}`}
          render={({ field: { value, onChange } }) => (
            <LokaliseAutocomplete
              label={t(`layouts.tds_gift_cards.fields.topUpDone.${name}` as LocaleKeys)}
              name={`${parent}.${name}`}
              value={value as string}
              onChange={onChange}
              required={required}
              data-testid={testIds.formField(name)}
            />
          )}
        />
      </FormField>
    );
  };

  const LokaliseFieldError = (
    parent: keyof TdsGiftCardsErrorsProps,
    name: keyof SharedModalBody,
    required = false
  ): React.ReactElement => {
    return (
      <FormField>
        <Controller
          control={control}
          name={`${parent}.${name}`}
          render={({ field: { value, onChange } }) => (
            <LokaliseAutocomplete
              label={t(`layouts.tds_gift_cards.fields.errors.${name}` as LocaleKeys)}
              name={`${parent}.${name}`}
              value={value as string}
              onChange={onChange}
              required={required}
              data-testid={testIds.formField(name)}
            />
          )}
        />
      </FormField>
    );
  };

  const LokaliseFieldsErrors = (name: keyof TdsGiftCardsErrorsProps): React.ReactElement => (
    <>
      {LokaliseFieldError(name, 'header', true)}
      {LokaliseFieldError(name, 'body', true)}
      {LokaliseFieldError(name, 'fstCta', true)}
      {LokaliseFieldError(name, 'sndCta', true)}
    </>
  );

  const AssetPicker = ({
    name,
    label,
    assetType,
    required = false
  }: {
    name: TdsGiftCardsProps;
    label: string;
    assetType: AssetTypesEnum;
    required?: boolean;
  }): JSX.Element => {
    const isRequired = (label: string) => (required ? markAsRequired(label) : label);
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormField>
            <AssetBrowser
              label={isRequired(label)}
              assetId={moduleId || ''}
              assetType={assetType}
              value={value as string}
              data-testid={testIds.formField(name)}
              hasError={!!error}
              onChange={onChange}
            />
          </FormField>
        )}
      />
    );
  };

  const BackgroundAssetPicker = ({
    prop,
    name,
    assetType,
    required = false
  }: BackgroundFieldProps & { assetType: AssetTypesEnum }): React.ReactElement => (
    <Controller
      control={control}
      name={`${prop}.${name}`}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormField>
          <AssetBrowser
            required={required}
            label={t(`layouts.tds_gift_cards.fields.${prop}.${name}`)}
            assetId={moduleId || ''}
            assetType={assetType}
            value={value as string}
            data-testid={testIds.formField(name)}
            hasError={!!error}
            onChange={onChange}
          />
        </FormField>
      )}
    />
  );

  const CheckboxField = ({ name }: { name: TdsGiftCardsCheckboxProps }): React.ReactElement => (
    <FormField>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={t(`layouts.tds_gift_cards.fields.${name}` as LocaleKeys)}
            control={
              <Switch
                color={formControlColor}
                checked={value}
                onChange={onChange}
                data-testid={testIds.formField(name)}
              />
            }
          />
        )}
      />
    </FormField>
  );

  const onAddCodeHints = () => {
    appendCodeHints({
      id: generateId(),
      codeHint: ''
    });
  };

  return (
    <FormBody data-testid={testIds.formBody}>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.redemption_flow')}</SectionHeader>
        <AssetPicker
          name="logo"
          label={t('layouts.tds_gift_cards.fields.logo')}
          assetType={AssetTypes.tdsGiftCards.logo}
          required
        />
        <AssetPicker
          name="partnerLogo"
          label={t('layouts.tds_gift_cards.fields.partnerLogo')}
          assetType={AssetTypes.tdsGiftCards.partnerLogo}
        />
        <Controller
          control={control}
          name={'stepOneBackground.id'}
          defaultValue={generateId()}
          render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="portraitFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundPortraitFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="landscapeFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundLandscapeFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="mobileFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundMobileFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="ctvFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundCtvFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="desktopFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundDesktopFillImage}
        />
        <BackgroundAssetPicker
          prop="stepOneBackground"
          name="tabletFillImage"
          assetType={AssetTypes.tdsGiftCards.backgroundTabletFillImage}
        />
        <LokaliseField
          name="stepOneHeading"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepOneHeading')}
          required
        />
        <LokaliseField
          name="stepOneSubheader"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepOneSubheader')}
        />
        <LokaliseField
          name="stepOneEmail"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepOneEmail')}
          required
        />
        <LokaliseField
          name="stepOnePassword"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepOnePassword')}
          required
        />
        <LokaliseField
          name="stepOneLegalCopy"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepOneLegalCopy')}
        />
        <LokaliseField
          name="stepOneCta"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepOneCta')}
          required
        />
        <LokaliseField
          name="stepOneSignIn"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepOneSignIn')}
          required
        />
        <CheckboxField name="stepOneCheckbox" />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.enter_code')}</SectionHeader>
        <LokaliseField
          name="stepTwoHeading"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoHeading')}
          required
        />
        <LokaliseField
          name="stepTwoSubheader"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoSubheader')}
        />
        <LokaliseField
          name="stepTwoHowToHelper"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoHowToHelper')}
        />
        <AssetPicker
          name="stepTwoHowToImage"
          label={t('layouts.tds_gift_cards.fields.stepTwoHowToImage')}
          assetType={AssetTypes.tdsGiftCards.stepTwoHowToImage}
        />
        <LokaliseField
          name="stepTwoHowToHelper"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoHowToHelper')}
        />
        <LokaliseField
          name="stepTwoHowToHeader"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoHowToHeader')}
        />
        <LokaliseField
          name="stepTwoHowToSteps"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoHowToSteps')}
        />
        <LokaliseField
          name="stepTwoHowToCtaOne"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoHowToCtaOne')}
        />
        <LokaliseField
          name="stepTwoHowToCtaTwo"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoHowToCtaTwo')}
        />
        <LokaliseField
          name="stepTwoCta"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepTwoCta')}
          required
        />
        <div>
          <Sortable
            list={fieldsCodeHints}
            setList={replaceCodeHintsMethod}
            ghostClass="sortableGhost"
            dragClass="sortableDragDefault"
          >
            {fieldsCodeHints.map((suggestion, i) => (
              <Stack
                key={suggestion.id}
                data-testid={testIds.codeHint}
                gap={6}
                marginBottom={5}
                direction="row"
                alignItems="center"
              >
                {LokaliseFieldCodeHints('codeHint', i)}
                <IconButton
                  title={t('layouts.tds_gift_cards.actions.remove_code_hints')}
                  disabled={fieldsCodeHints.length === 1}
                  onClick={() => removeCodeHints(i)}
                  sx={{ mt: 4 }}
                  data-testid={testIds.codeHintRemoveButton}
                >
                  <Delete />
                </IconButton>
              </Stack>
            ))}
          </Sortable>
          <Button
            startIcon={<Add />}
            color={formControlColor}
            variant="outlined"
            onClick={onAddCodeHints}
            data-testid={testIds.codeHintAddButton}
          >
            {t('layouts.tds_gift_cards.actions.add_code_hints')}
          </Button>
        </div>
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.preview_new_user')}</SectionHeader>
        <LokaliseField
          name="stepThreeAHeading"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAHeading')}
          required
        />
        <LokaliseField
          name="stepThreeASubheader"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeASubheader')}
        />
        <LokaliseField
          name="stepThreeAPlanHeader"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAPlanHeader')}
        />
        <LokaliseField
          name="stepThreeAPlanName"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAPlanName')}
        />
        <LokaliseField
          name="stepThreeAPlanDescription"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAPlanDescription')}
        />
        <LokaliseField
          name="stepThreeAPlanPrice"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAPlanPrice')}
        />
        <LokaliseField
          name="stepThreeAPlanTotalLabel"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAPlanTotalLabel')}
        />
        <LokaliseField
          name="stepThreeAPlanTotalPrice"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAPlanTotalPrice')}
        />
        <LokaliseField
          name="stepThreeAReminderHeading"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAReminderHeading')}
        />
        <LokaliseField
          name="stepThreeAReminderOneStartDate"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAReminderOneStartDate')}
        />
        <LokaliseField
          name="stepThreeAReminderTwoEndDate"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeAReminderTwoEndDate')}
        />
        <CheckboxField name="stepThreeACheckbox" />
        <LokaliseField
          name="stepThreeALegalCopy"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeALegalCopy')}
          required
        />
        <LokaliseField
          name="stepThreeACtaOne"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeACtaOne')}
          required
        />
        <LokaliseField
          name="stepThreeACtaTwo"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeACtaTwo')}
          required
        />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.top_up')}</SectionHeader>
        <LokaliseField
          name="stepThreeBHeadingTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBHeadingTopUp')}
          required
        />
        <LokaliseField
          name="stepThreeBSubheaderTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBSubheaderTopUp')}
        />
        <LokaliseField
          name="stepThreeBPlanHeaderTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBPlanHeaderTopUp')}
        />
        <LokaliseField
          name="stepThreeBPlanNameTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBPlanNameTopUp')}
        />
        <LokaliseField
          name="stepThreeBPlanDescriptionTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBPlanDescriptionTopUp')}
        />
        <LokaliseField
          name="stepThreeBPlanPriceTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBPlanPriceTopUp')}
        />
        <LokaliseField
          name="stepThreeBPlanTotalLabelTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBPlanTotalLabelTopUp')}
        />
        <LokaliseField
          name="stepThreeBPlanTotalPriceTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBPlanTotalPriceTopUp')}
        />
        <LokaliseField
          name="stepThreeBReminderHeadingTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBReminderHeadingTopUp')}
        />
        <LokaliseField
          name="stepThreeBReminderOneStartDateTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBReminderOneStartDateTopUp')}
        />
        <LokaliseField
          name="stepThreeBReminderTwoEndDateTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBReminderTwoEndDateTopUp')}
        />
        <CheckboxField name="stepThreeBCheckboxTopUp" />
        <LokaliseField
          name="stepThreeBLegalCopyTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBLegalCopyTopUp')}
          required
        />
        <LokaliseField
          name="stepThreeBCtaOneTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBCtaOneTopUp')}
          required
        />
        <LokaliseField
          name="stepThreeBCtaTwoTopUp"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeBCtaTwoTopUp')}
          required
        />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.direct_billing')}</SectionHeader>
        <LokaliseField
          name="stepThreeCHeadingDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCHeadingDtc')}
          required
        />
        <LokaliseField
          name="stepThreeCSubheaderDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCSubheaderDtc')}
        />
        <LokaliseField
          name="stepThreeCPlanHeaderDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCPlanHeaderDtc')}
        />
        <LokaliseField
          name="stepThreeCPlanNameDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCPlanNameDtc')}
        />
        <LokaliseField
          name="stepThreeCPlanDescriptionDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCPlanDescriptionDtc')}
        />
        <LokaliseField
          name="stepThreeCPlanPriceDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCPlanPriceDtc')}
        />
        <LokaliseField
          name="stepThreeCPlanTotalLabelDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCPlanTotalLabelDtc')}
        />
        <LokaliseField
          name="stepThreeCPlanTotalPriceDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCPlanTotalPriceDtc')}
        />
        <LokaliseField
          name="stepThreeCBalanceDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCBalanceDtc')}
        />
        <LokaliseField
          name="stepThreeCReminderHeadingDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCReminderHeadingDtc')}
        />
        <LokaliseField
          name="stepThreeCReminderOneStartDateDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCReminderOneStartDateDtc')}
        />
        <LokaliseField
          name="stepThreeCReminderTwoEndDateDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCReminderTwoEndDateDtc')}
        />
        <LokaliseField
          name="stepThreeCReminderThreeDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCReminderThreeDtc')}
        />
        <CheckboxField name="stepThreeCCheckboxDtc" />
        <LokaliseField
          name="stepThreeCLegalCopyDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCLegalCopyDtc')}
          required
        />
        <LokaliseField
          name="stepThreeCCtaOneDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCCtaOneDtc')}
          required
        />
        <LokaliseField
          name="stepThreeCCtaTwoDtc"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCCtaTwoDtc')}
          required
        />
        <LokaliseField
          name="stepThreeCBalanceDtcHeading"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepThreeCBalanceDtcHeading')}
        />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.welcome')}</SectionHeader>
        <Controller
          control={control}
          name={'stepFourBackground.id'}
          defaultValue={generateId()}
          render={({ field: { value, onChange } }) => <input type="hidden" value={value} onChange={onChange} />}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="portraitFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundPortraitFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="landscapeFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundLandscapeFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="mobileFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundMobileFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="ctvFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundCtvFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="desktopFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundDesktopFillImage}
        />
        <BackgroundAssetPicker
          prop="stepFourBackground"
          name="tabletFillImage"
          assetType={AssetTypes.tdsGiftCards.stepFourBackgroundTabletFillImage}
        />
        <LokaliseField
          name="stepFourHeading"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepFourHeading')}
          required
        />
        <LokaliseField
          name="stepFourSubheader"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepFourSubheader')}
        />
        <LokaliseField
          name="stepFourLegalCopy"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepFourLegalCopy')}
        />
        <CheckboxField name="stepFourCheckbox" />
        <LokaliseField
          name="stepFourCtaOne"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepFourCtaOne')}
          required
        />
        <LokaliseField
          name="stepFourCtaTwo"
          control={control}
          toTranslate="layouts.tds_gift_cards.fields"
          dataTestId={testIds.formField('stepFourCtaTwo')}
          required
        />
      </Box>
      <Box>
        <SectionHeader>{t('layouts.tds_gift_cards.headers.top_up_done')}</SectionHeader>
        {LokaliseFieldTopUpDone('topUpDone', 'message', true)}
        {LokaliseFieldTopUpDone('topUpDone', 'cta', true)}
      </Box>
      <Box>
        <SectionHeader>{`${t('layouts.tds_gift_cards.headers.warning_messages')} - ${t(
          'layouts.tds_gift_cards.headers.unsupported_payment_method'
        )}`}</SectionHeader>
        {LokaliseFieldsErrors('notSupportedPaymentMethodError')}
      </Box>
      <Box>
        <SectionHeader>{`${t('layouts.tds_gift_cards.headers.warning_messages')} - ${t(
          'layouts.tds_gift_cards.headers.unable_to_redeem'
        )}`}</SectionHeader>
        {LokaliseFieldsErrors('unableToRedeemError')}
      </Box>
      <Box>
        <SectionHeader>{`${t('layouts.tds_gift_cards.headers.warning_messages')} - ${t(
          'layouts.tds_gift_cards.headers.used_account'
        )}`}</SectionHeader>
        {LokaliseFieldsErrors('usedAccountError')}
      </Box>
    </FormBody>
  );
});

TdsGiftCardsForm.displayName = 'TdsGiftCardsForm';

export default TdsGiftCardsForm;
